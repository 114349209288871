export const APP_VERSION = '0.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT'
};

export const PTBR_TABLE_TRANSLATE = {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'Linhas'
  },
  toolbar: {
    nRowsSelected: '{0} linha(s) selecionada(s)',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar'
  },
  header: {
    actions: 'Alterar'
  },
  body: {
    emptyDataSourceMessage: 'Lista Vazia',
    addTooltip: 'adicionar',
    deleteTooltip: 'deletar',
    editTooltip: 'editar',
    filterRow: {
      filterTooltip: 'Filtro'
    },
    editRow: {
      deleteText: 'Tem certeza que quer Deletar este item?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Salvar'
    }
  }
};

export const styleTable = {
  headerStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2',
    color: 'rgb(151 24 0)'
  },
  cellStyle: {
    borderLeft: '1px solid #f2f2f2',
    borderRight: '1px solid #f2f2f2'
  }
};

export const LOGO = '/static/logo.svg';

export const LOGO_WHITE = '/static/logo.svg';

export const BRAND_PRIMARY_COLOR = '#960000';
export const BRAND_SECONDARY_COLOR = '#FFFFFF';

export const IMG_ERROR = '/static/img-error.svg';
